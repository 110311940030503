// Skills Icons
import htmlIcon from "./images/html.svg";
import cssIcon from "./images/css.svg";
import reactIcon from "./images/react.svg";
import jsIcon from "./images/javascript.svg";
import designIcon from "./images/design.svg";
import angular from './images/skills/angular.svg';
import aws from './images/skills/aws.svg';
import axios from './images/skills/axios-icon.svg';
import bootstrap from './images/skills/bootstrap.svg';
import digital from './images/skills/digitalOcean.svg';
import git from './images/skills/git.png';
import jquery from './images/skills/jquery.svg';
import mongo from './images/skills/mongodb.svg';
import sql from './images/skills/mysql.svg';
import nginx from './images/skills/nginx.svg';
import node from './images/skills/nodejs.svg';
import passport from './images/skills/passport.svg';
import postgres from './images/skills/postgres.svg';
import postman from './images/skills/postman.svg';
import socket from './images/skills/socket-io.svg';
import typescript from './images/skills/typescript.svg';
import vs from './images/skills/vs.svg';

// Social Icon
import githubIcon from "./images/github.svg"
import linkedInIcon from './images/linkedin.svg'
import instagramIcon from "./images/instagram.svg"
import resumeIcon from './images/resume.svg'

export default {
  //(Please Do Not Remove The comma(,) after every variable)
  //Change The Website Template

  //   Header Details ---------------------
  name: "Larry",
  headerTagline: [
    //Line 1 For Header
    "Software Developer",
  ],
  //   Header Paragraph
  headerParagraph:
    'a jack of all trades is a master of none but oftentimes better than a master of one',

  //Contact Email
  contactEmail: "lschwall4@gmail.com",

  // End Header Details -----------------------

  // Work Section ------------------------
  projects: [
    {
      id: 1, //DO NOT CHANGE THIS (Please)😅
      title: "Prost", //Project Title - Add Your Project Title Here
      para:
        "Creating social environments for the consumption of alcohol while promoting both safety and local businesses.", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://i.imgur.com/KB7KVNv.png",
      //Project URL - Add Your Project Url Here
      url: "https://github.com/CodingUnderTheInfluence/Prost",
    },
    {
      id: 2, //DO NOT CHANGE THIS (Please)😅
      title: "Radma", //Project Title - Add Your Project Title Here
      para:
        "A web application designed to help users find and connect to local bands.", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://i.imgur.com/GE2HaOh.png",
      //Project URL - Add Your Project Url Here
      url: "https://github.com/Team-Schrodingers-Cats/Rad-Music-App",
    },
    {
      id: 3, //DO NOT CHANGE THIS (Please)😅
      title: "Qwiktionary", //Project Title - Add Your Project Title Here
      para:
        "A mobile dictionary app with that speeds up your verbose.", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://i.imgur.com/3YfqgdB.png",
      //Project URL - Add Your Project Url Here
      url: "https://github.com/lschwall/qwiktionary",
    },
    {
      id: 4, //DO NOT CHANGE THIS (Please)😅
      title: "Blogs", //Project Title - Add Your Project Title Here
      para:
        "My recent blog posts.", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://i.imgur.com/3TMdCei.jpg",
      //Project URL - Add Your Project Url Here
      url: "https://dev.to/lschwall",
    },
    // {
    //   id: 4, //DO NOT CHANGE THIS (Please)😅
    //   title: "Project Four", //Project Title - Add Your Project Title Here
    //   para:
    //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.", // Add Your Service Type Here
    //   //Project Image - Add Your Project Image Here
    //   imageSrc:
    //     "https://images.unsplash.com/photo-1492171983775-a51717616c0d?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8NjB8fHBhc3RlbHxlbnwwfHwwfA%3D%3D&auto=format&fit=crop&w=400&q=60",
    //   //Project URL - Add Your Project Url Here
    //   url: "http://chetanverma.com/",
    // },
    // {
    //   id: 5, //DO NOT CHANGE THIS (Please)😅
    //   title: "Project Five", //Project Title - Add Your Project Title Here
    //   para:
    //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.", // Add Your Service Type Here
    //   //Project Image - Add Your Project Image Here
    //   imageSrc:
    //     "https://images.unsplash.com/photo-1534239143101-1b1c627395c5?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8NzZ8fHBhc3RlbHxlbnwwfHwwfA%3D%3D&auto=format&fit=crop&w=400&q=60",
    //   //Project URL - Add Your Project Url Here
    //   url: "http://chetanverma.com/",
    // },
    // {
    //   id: 6, //DO NOT CHANGE THIS (Please)😅
    //   title: "Project Six", //Project Title - Add Your Project Title Here
    //   para:
    //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.", // Add Your Service Type Here
    //   //Project Image - Add Your Project Image Here
    //   imageSrc:
    //     "https://images.unsplash.com/photo-1517479149777-5f3b1511d5ad?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8MTAyfHxwYXN0ZWx8ZW58MHx8MHw%3D&auto=format&fit=crop&w=400&q=60",
    //   //Project URL - Add Your Project Url Here
    //   url: "http://chetanverma.com/",
    // },

    /*
    If You Want To Add More Project just Copy and Paste This At The End (Update the id Respectively)
    ,{
        id: 5,
        title: 'Project Five',
        para: 'Something Amazing',
        imageSrc: "",
        url: ''
    }
    */
  ],

  // End Work Section -----------------------

  // About Secton --------------
  aboutParaOne:
    "Hi, I'm Larry! Born and raised in the heart of New Orleans with a passion for coding, cooking, and culture. I come from a background in automotive repair, Architecture and the arts, carpentry, culinary, graphical design, photography, and software development.",
  aboutParaTwo:
    `Some would call me "a jack of all trades, master of none"; however, I always love to refer to myself as "a jack of all trades that's a master of some, but oftentimes that is better than a master of one"`,
  aboutParaThree:
    "Most days, you can find me working on various projects that uses P.E.R.N. and M.E.R.N. stacks. Other days, you'll find me building furniture, taking photos, grabbing a beer with some buds, or playing board games.",
  aboutParaFour:
    "I have over 5 years of experience with Html5 and CSS3. A little over 2 years with JavaScript. I recently graduated from Operation Spark in New Orleans, La as a Full Stack Web Engineer with a certificate in IBC fundamentals of Javascript, Functional Programming and Web Development Level 3.",
  aboutParaFive:
    "I have an incredible passion for technology and how it can influence the world. I find myself falling in love everyday with new technologies and how they can help people access the world before them.",
  aboutImage:
    "https://i.imgur.com/NgJdKLM.jpg",

  //   End About Section ---------------------

  // Skills Section ---------------

  //   Import Icons from the top and link it here

  skills: [
    {
      id: 1,
      img: htmlIcon,
      para:
        "Html5",
    },
    {
      id: 2,
      img: cssIcon,
      para:
        "CSS3",
    },
    {
      id: 3,
      img: jsIcon,
      para:
        `Es5 | Es6 | Es7 | Es8 | Es9`,
    },
    {
      id: 4,
      img: reactIcon,
      para:
        "React | React Router | React Hooks",
    },
    {
      id: 5,
      img: designIcon,
      para:
        "Adobe Suite |  Autodesk Suite",
    },
    {
      id: 6,
      img: aws,
      para:
        "Amazon Deploy",
    },
    {
      id: 7,
      img: axios,
      para:
        "Axios",
    },
    {
      id: 8,
      img: bootstrap,
      para:
        "BootStrap",
    },
    {
      id: 9,
      img: digital,
      para:
        "DigitalOcean",
    },
    {
      id: 10,
      img: git,
      para:
        "Git",
    },
    {
      id: 11,
      img: mongo,
      para:
        "MongoDB",
    },
    {
      id: 12,
      img: sql,
      para:
        "MySql",
    },
    {
      id: 13,
      img: nginx,
      para:
        "Nginx",
    },
    {
      id: 14,
      img: node,
      para:
        "NodeJs",
    },
    {
      id: 15,
      img: postgres,
      para:
        "PostgreSql",
    },
    {
      id: 16,
      img: passport,
      para:
        "PassportJs Oauth 2.0",
    },
    {
      id: 17,
      img: postman,
      para:
        "Postman",
    },
    {
      id: 18,
      img: socket,
      para:
        "Socket.io",
    },
    {
      id: 19,
      img: typescript,
      para:
        "TypeScript",
    },
    {
      id: 20,
      img: vs,
      para:
        "Visual Studio | Visual Studio Code",
    },
    {
      id: 21,
      img: angular,
      para:
        "Angular 8 | Angular 9 | Modern Angular",
    },
    {
      id: 22,
      img: jquery,
      para:
        "JQuery",
    },
  ],

  // End Skills Section --------------------------

  //   Promotion Section --------------------------

  // promotionHeading: "Heading",
  // promotionPara:
  //   "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  // End Promotion Section -----------------

  //   Contact Section --------------

  contactSubHeading: "Let's make something awesome together",
  social: [
    // Add Or Remove The Link Accordingly
    {
      img: githubIcon,
      url: "https://github.com/lschwall",
      title: "Github"
    },
    {
      img: linkedInIcon,
      url: "https://www.linkedin.com/in/larryschwalliv",
      title: "LinkedIn"
    },
    {
      img: instagramIcon,
      url: "https://www.instagram.com/_larry4",
      title: "Instagram"
    },
    {
      img: resumeIcon,
      url: "https://drive.google.com/file/d/1lGQwCTZakRu1W20o8UVkst7wY5KK2dS_/view?usp=sharing",
      title: "Resume"
    },
  ],

  // End Contact Section ---------------
}

// Thanks for using this template, I would love to hear from you contact me at hello@chetanverma.com
