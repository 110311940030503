import React from "react"
import Fade from "react-reveal/Fade"
import Typewriter from 'typewriter-effect'
import data from "../yourdata"

const Header = () => {
  return (
    <div className="section" id="home">
      <div className="container">
        <div className="header-wrapper">
          <Fade bottom>
            <h2>Hi, I'm{" "}
              {data.name}.
            </h2>
          </Fade>
          <Fade bottom cascade>
            <div className="heading-wrapper">
              <h1>
                <Typewriter
                  options={{
                    strings: [
                      'Software Developer',
                      'Full Stack Engineer',
                      'Photographer',
                      'Carpenter',
                      'Gamer',
                      'Dedicated',
                      'Reliable',
                      'Driven'
                    ],
                    autoStart: true,
                    loop: true,
                  }}
                />
              </h1>
            </div>
          </Fade>
          <Fade bottom>
            <p>{data.headerParagraph}</p>
          </Fade>
          <Fade bottom>
            <a
              target="_blank"
              href={`https://mail.google.com/mail/u/0/?fs=1&tf=cm&to=lschwall4@gmail.com`}
              className="primary-btn"
            >
              CONNECT WITH ME
            </a>
          </Fade>
        </div>
      </div>
    </div>
  )
}

export default Header
